import React from "react";
import Image from "next/legacy/image"
import makeStyles from '@mui/styles/makeStyles';
// @mui/icons-material
// import  from "@mui/icons-material/";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
// import CardAvatar from "components/Card/CardAvatar.js";
import CardHeader from "components/Card/CardHeader.js";
// import CardBody from "components/Card/CardBody.js";
// import CardFooter from "components/Card/CardFooter.js";
import Button from "components/CustomButtons/Button.js";
// import Muted from "components/Typography/Muted.js";

import teamsStyle from "styles/jss/nextjs-material-kit-pro/pages/sectionsSections/teamsStyle.js";

const useStyles = makeStyles(teamsStyle);

export default function SectionTeams({ ...rest }) {
  const classes = useStyles();
  return (
    <div className="cd-section" {...rest}>

      {/* Team 5 START */}
      <div
      className="clients"
        //className={classes.team + " " + classes.section}
       style={{ backgroundImage: "url('/img/home-page/10.jpg')" }}
        //style={{ backgroundColor: "lightgrey"}}
      >
        <div className={classes.container} >
          <GridContainer>
            <GridItem
              xs={12}
              sm={8}
              md={8}
              className={
                classes.mlAuto + " " + classes.mrAuto + " " + classes.textCenter
              }
            >
              <h2 className={classes.title}>Some other companies we work with</h2>
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={6} sm={3} md={3}>
              <Card profile plain className={classes.card5} style={{paddingLeft: "25%",paddingRight: "25%",paddingTop: "5%",paddingBottom: "0%"}}>
                
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <CardHeader image plain noShadow>
                      <a href="#pablo" onClick={(e) => e.preventDefault()}>
                        <Image
                          layout="responsive"
                          width = {128}
                          height = {97}
                          src="/img/clients/PricewaterhouseCoopers-grey.webp"
                          alt="..."
                        />
                      </a>
                    </CardHeader>
                  </GridItem>
                  
                </GridContainer>
              </Card>
            </GridItem>
            <GridItem xs={6} sm={3} md={3}>
            <Card profile plain className={classes.card5} style={{paddingLeft: "25%",paddingRight: "25%",paddingTop: "0%",paddingBottom: "0%"}}>
                
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <CardHeader image plain noShadow>
                      <a href="#pablo" onClick={(e) => e.preventDefault()}>
                        <Image
                          layout="responsive"
                          width = {128}
                          height = {107}
                          src="/img/clients/afl-nsw-act-logo-grey.webp"
                          alt="..."
                        />
                      </a>
                    </CardHeader>
                  </GridItem>
                  
                </GridContainer>
              </Card>
            </GridItem>
            <GridItem xs={6} sm={3} md={3}>
            <Card profile plain className={classes.card5} style={{paddingLeft: "10%",paddingRight: "10%",paddingTop: "5%",paddingBottom: "0%"}}>
                
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <CardHeader image plain noShadow>
                        <Image
                          layout="responsive"
                          width = {204}
                          height = {99}
                          src="/img/clients/BETFAIR-grey-small.webp"
                          alt="..."
                        />
                    </CardHeader>
                  </GridItem>
                  
                </GridContainer>
              </Card>
            </GridItem>
            <GridItem xs={6} sm={3} md={3}>
            <Card profile plain className={classes.card5} style={{paddingLeft: "10%",paddingRight: "10%",paddingTop: "5%",paddingBottom: "0%"}}>
                
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <CardHeader image plain noShadow>
                        <Image
                          layout="responsive"
                          width = {204}
                          height = {70}
                          src="/img/clients/Steadfast-grey-small.webp"
                          alt="..."
                        />
                    </CardHeader>
                  </GridItem>
                  
                </GridContainer>
              </Card>
            </GridItem>
           
            <GridItem xs={6} sm={3} md={3}>
              <Card profile plain className={classes.card5} style={{paddingLeft: "25%",paddingRight: "25%",paddingTop: "0%",paddingBottom: "0%"}}>
                
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <CardHeader image plain noShadow>
                        <Image
                          layout="responsive"
                          width = {127}
                          height = {120}
                          src="/img/clients/mamma-knows-west-grey-small.webp"
                          alt="..."
                        />
                    </CardHeader>
                  </GridItem>
                  
                </GridContainer>
              </Card>
            </GridItem>
            <GridItem xs={6} sm={3} md={3}>
            <Card profile plain className={classes.card5} style={{paddingLeft: "5%",paddingRight: "5%",paddingTop: "15%",paddingBottom: "0%"}}>
                
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <CardHeader image plain noShadow>
                        <Image
                          layout="responsive"
                          width = {229}
                          height = {37}
                          src="/img/clients/midlandbrick-grey.webp"
                          alt="..."
                        />
                    </CardHeader>
                  </GridItem>
                  
                </GridContainer>
              </Card>
            </GridItem>
            <GridItem xs={6} sm={3} md={3}>
            <Card profile plain className={classes.card5} style={{paddingLeft: "10%",paddingRight: "10%",paddingTop: "10%",paddingBottom: "0%"}}>
                
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <CardHeader image plain noShadow>
                        <Image
                          layout="responsive"
                          width = {204}
                          height = {65}
                          src="/img/clients/prezzee-grey.webp"
                          alt="..."
                        />
                    </CardHeader>
                  </GridItem>
                  
                </GridContainer>
              </Card>
            </GridItem>
            <GridItem xs={6} sm={3} md={3}>
            <Card profile plain className={classes.card5} style={{paddingLeft: "15%",paddingRight: "15%",paddingTop: "0%",paddingBottom: "0%"}}>
                
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <CardHeader image plain noShadow>
                        <Image
                          layout="responsive"
                          width = {178}
                          height = {123}
                          src="/img/clients/rsd-grey.webp"
                          alt="..."
                        />
                    </CardHeader>
                  </GridItem>
                  
                </GridContainer>
              </Card>
            </GridItem>

          </GridContainer>
          
        </div>

        <br />
        <div className="buttonCentre" style={{textAlign: "center"}}>
          <a href ="/clients">
          <Button color="warning" size="lg">See more clients</Button>
          </a>
        </div>  
        <br /><br />  
      </div>

      {/* Team 5 END */}
    </div>
  );
}
